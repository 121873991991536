@import "../../../styles/common";

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--layer-5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem var(--Header-horizontal-spacing);
  width: 100%;
  height: var(--Header-height);
  background-color: var(--color-white);
  box-shadow: var(--shadow-header);

  @include bp-down(md) {
    gap: .5rem;
  }
}

.logo {
    width: 100%;
    max-width: 17rem;
    max-height: 1.5rem;

    @include bp-down(md) {
      max-width: 13.5rem;
    }
  }

.Loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
