@import "../../styles/common";

.root {
  width: 100%;
  height: 42rem;
  max-height: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp-down(2xl) {
    height: 32rem;
  }

  &--small {
    height: 12.5rem;
  }

  &--medium {
    height: 20rem;
  }

  &--full {
    height: 100%;
    flex: 1;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  background-color: var(--color-gray-25);
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-gray-500);
}

.title {
  margin-bottom: .5rem;
  color: var(--color-black);
}

.content {
  max-width: 22rem;
  text-align: center;
  color: var(--color-gray-500);
}

.action {
  margin-top: 1.5rem;
}
