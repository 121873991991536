.ModalInfo {

  &__block {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: .25rem;
    background: var(--color-white);
    box-shadow: var(--shadow-card);
    padding: 1.5rem;
  }

  &__link-toast {
    color: white;
    font-weight: 700;
  }
}

.toastLink {
  color: white;
  font-weight: 700;
}
