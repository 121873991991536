.root {
  // This style makes `<main/>` element to be of at least 100% height of the browser window,
  // minus the header height. That's because the parent element of `<main/>` is declared as
  // `display: flex` and `flex-direction: column`.
  flex-grow: 1;

  // These styles make the `<main/>` element into a "flexbox" one.
  // This way, a child element of `<main/>`, such as `<AcadeumBackground/>`,
  // can stretch itself vertically to be of at least 100% height of the browser window,
  // minus the header height. For that, the child element should be styled with `flex-grow: 1`.
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - var(--Header-height));

  padding: 1.5rem var(--main-horizontal-spacing) 2rem;
}
