.CourseSectionsTab {
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otherSectionsCostOverwriteWarning {
  color: var(--color-red-400);
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.otherSectionsCostOverwriteWarningIcon {
  width: 1rem;
  height: 1rem;
  margin-bottom: 0.35rem;
  margin-right: 0.25rem;
  color: var(--color-yellow-500);
}
