@import "../../styles/common";

.button {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  width: 100%;
  min-height: 2rem;
  padding: .25rem .5rem .25rem .75rem;
  border: none;
  border-radius: .25rem;
  font-weight: 400;
  box-shadow: inset 0 0 0 1px var(--color-gray-25);
  background-color: var(--color-gray-25);
  color: var(--color-grayish-300);
  transition: all .15s ease-in-out;
  cursor: pointer;
  outline: none;

  &.white {
    box-shadow: var(--shadow-border-light);
    background-color: var(--color-white);
    color: var(--color-black);
  }

  &:not([disabled]):hover {
    background-color: var(--color-white);
    box-shadow: var(--shadow-border-purple);
  }

  &[data-active="true"] {
    color: var(--color-black);

    &.multiple {
      background-color: var(--color-white);
    }
  }

  &[aria-invalid="true"] {
    box-shadow: var(--shadow-border-error);
  }

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .7;
  }
}

.buttonContent {
  flex: 1;
  display: flex;
  align-items: center;
  gap: .5rem;
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: .25rem;
}

[aria-expanded="false"] {
  .tags {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}


.numberOfHiddenTags {
  margin-left: auto;
  padding: .25rem .5rem;
  color: var(--color-black);
  background-color: var(--color-purple-100);
  border-radius: 0.25rem;
  display: flex;
  text-wrap: nowrap;
  height: 100%;
}

.caretIcon {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
}

.dialog {
  padding: 0;
  min-width: 9.5rem; // This is a fallback if `max()` doesn't work
  min-width: max(9.5rem, var(--radix-popover-trigger-width));
  outline: none;
  max-width: min-content;
}

.inputWrapper {
  padding: .5rem .75rem;
}

.group {
  max-height: 10rem;
  overscroll-behavior: contain;
  overflow-y: auto;
  overflow-x: hidden;
}

.multiOption,
.singleOption {
  padding: 0.25rem .75rem;
  cursor: pointer;
  color: var(--color-black);

  // :hover
  &[data-selected="true"] {
    color: var(--color-white);
    background-color: var(--color-purple-500);
  }
}

.singleOption {
  &:where([data-active="true"]) {
    color: var(--color-purple-600);
    background-color: var(--color-gray-15);
  }
}

.multiOption {
  &:where([data-active="true"]) {
    background-color: var(--color-gray-15);
  }
}

.optionHeader {
  display: flex;
  align-items: center;
}

.optionLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}

.optionCount {
  margin-left: 1rem;
}

.optionDescription {
  color: var(--color-gray-500);

  [data-active="true"] &,
  [data-selected="true"] & {
    color: inherit;
  }
}

.empty {
  padding: 0.25rem .75rem;
  text-align: center;
}

.multiCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-right: .5rem;
  border: 1px solid #595959;
  border-radius: 1px;

  [data-active="true"] & {
    background-color: var(--color-purple-600);
    color: var(--color-white);
  }

  &,
  [data-selected="true"] & {
    background-color: var(--color-white);
    color: var(--color-purple-600);
  }
}

.singleCheckIcon {
  width: 1.25rem;
  height: 1.25rem;
  opacity: 0;
  margin-left: auto;

  [data-active="true"] & {
    opacity: 1;
  }
}

.action {
  width: 100%;
  padding: 0.25rem .75rem;
  line-height: 1.75rem;
  border-top: 1px solid var(--color-grayish-100);
  color: var(--color-purple-600);

  &:hover,
  &:focus-visible {
    background-color: var(--color-purple-600);
    color: var(--color-white);
  }
}

.form {
  padding: .75rem 1rem 1rem;
}

.errorTooltip {
  position: absolute;
  top: .45rem;
  right: 1.75rem;
}
