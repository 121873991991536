.THead {
}

.tr {

}

.th {
  background: var(--color-gray-50);
  color: var(--color-black);
  padding-left: 2rem;

  padding-top: 1rem;
  padding-bottom: 1rem;

  position: sticky;
  top: 0;

  &.select {
    // Select column width must be minimum width
    width: 1px;
  }

  &:first-child,
  &.expand + th,
  &.select + th {
    padding-left: 1rem;
  }

  &.expand + th.select {
    padding-left: .5rem;
  }

  &:last-child {
    padding-right: 1rem;
    z-index: 3;
  }

  &:not(:first-child) {
    vertical-align: text-bottom;
  }
}

.CellLabel {
  display: flex;
  align-items: center;

  .sortIcon {
    width: .75rem;
    height: .75rem;
    margin-left: .25rem;
    opacity: 0;
    transition: opacity .15ms ease-in-out;
  }

  &.canSort .sortIcon {
    opacity: .6;
  }

  &:hover .sortIcon,
  &.isSorted .sortIcon {
    opacity: 1;
  }
}

.columnName {
  white-space: nowrap;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.ColumnFilter {
  margin-top: .25rem;
}
