@import "../../styles/common";

.root {
  padding: .5rem;
  border-radius: .25rem;
  display: inline-flex;
  align-items: center;
  min-height: 2rem;
  font-size: .875rem;
  background-color: var(--color-gray-50);
  color: var(--color-black);
  min-width: 6rem;
  justify-content: center;
  white-space: nowrap;

  @include bp-down(md) {
    margin-bottom: .5rem;
    padding: .25rem .5rem;
  }

  svg {
    width: 1rem;
    margin-right: .3rem;
  }

  @include bp(md) {
    padding: .25rem .5rem;
  }
}

.green {
  color: var(--color-green-700);
  background-color: var(--color-green-50);
}

.dark-green {
  color: var(--color-green-800);
  background-color: var(--color-green-200);
}

.purple {
  color: var(--color-blue-900);
  background-color: var(--color-purple-100);
}

.purple-light {
  color: var(--color-purple-600);
  background-color: var(--color-purple-100);
}

.grey {
  color: var(--color-gray-700);
  background-color: var(--color-gray-50);
}

.dark-grey {
  color: var(--color-gray-800);
  background-color: var(--color-gray-200);
}

.orange {
  color: var(--color-brown-700);
  background-color: var(--color-brown-100);
}

.dark-red {
  color: var(--color-red-800);
  background-color: var(--color-red-100);
}

.light-red {
  color: var(--color-red-900);
  background-color: var(--color-red-50);
}

.dark-blue {
  color: var(--color-blue-800);
  background-color: var(--color-blue-100);
}

.light-blue {
  color: var(--color-blue-700);
  background-color: var(--color-blue-50);
}

.yellow {
  color: var(--color-brown-900);
  background-color: var(--color-yellow-100);
}

.pink {
  color: var(--color-pink-700);
  background-color: var(--color-pink-50);
}
