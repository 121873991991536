.trigger {
  display: inline-flex;
  align-items: center;
}

.icon {
  width: 1.125rem;
  height: 1.125rem;
}

.info {
  color: var(--color-purple-500);
}

.error {
  color: var(--color-red-400);
}
